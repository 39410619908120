import { Component } from 'lib'
import Image from 'Component/Asset/Image/Image'
import locale from 'Common/Locale/Locale'
import { localeToPrivacyNotice } from 'Common/Locale/Privacy'
import CookiePreferences from './CookiePreferences/CookiePreferences'

const localeToTermsOfService = {
  'en-US': 'https://www.riotgames.com/en/terms-of-service',
  'en-GB': 'https://www.riotgames.com/en/terms-of-service',
  'en-AU': 'https://www.riotgames.com/en/terms-of-service',
  'cs-CZ': 'https://www.riotgames.com/en/terms-of-service-CZ',
  'de-DE': 'https://www.riotgames.com/de/terms-of-service-DE',
  'el-GR': 'https://www.riotgames.com/en/terms-of-service-EL',
  'es-ES': 'https://www.riotgames.com/es/terms-of-service-ES',
  'es-MX': 'https://www.riotgames.com/es/terms-of-service-ES',
  'fr-FR': 'https://www.riotgames.com/fr/terms-of-service-FR',
  'hu-HU': 'https://www.riotgames.com/en/terms-of-service-HU',
  'it-IT': 'https://www.riotgames.com/en/terms-of-service-IT',
  'pl-PL': 'https://www.riotgames.com/en/terms-of-service-PL',
  'pt-BR': 'https://www.riotgames.com/pt-br/terms-of-service-BR',
  'ro-RO': 'https://www.riotgames.com/en/terms-of-service-RO',
  'ru-RU': 'https://www.riotgames.com/ru/terms-of-service-RU',
  'tr-TR': 'https://www.riotgames.com/tr/terms-of-service-TR',
  'ja-JP': 'https://www.riotgames.com/ja/terms-of-service-JP',
  'ko-KR': 'https://legal.kr.riotgames.com/tos',
  'zh-TW': 'https://www.riotgames.com/en/terms-of-service',
  'th-TH': 'https://www.riotgames.com/en/terms-of-service',
  'en-PH': 'https://www.riotgames.com/en/terms-of-service',
  'en-SG': 'https://www.riotgames.com/en/terms-of-service',
  'id-ID': 'https://www.riotgames.com/id/terms-of-service',
  'vi-VN': 'https://www.riotgames.com/vi/terms-of-service'
}

const localeToAccessibility = {
  'en-US': 'https://www.riotgames.com/en/accessibility',
  'en-GB': 'https://www.riotgames.com/en/accessibility',
  'en-AU': 'https://www.riotgames.com/en/accessibility',
  'cs-CZ': 'https://www.riotgames.com/cs/accessibility',
  'de-DE': 'https://www.riotgames.com/de/accessibility',
  'el-GR': 'https://www.riotgames.com/el/accessibility',
  'es-ES': 'https://www.riotgames.com/es/accessibility',
  'es-MX': 'https://www.riotgames.com/es-419/accessibility',
  'fr-FR': 'https://www.riotgames.com/fr/accessibility',
  'hu-HU': 'https://www.riotgames.com/hu/accessibility',
  'it-IT': 'https://www.riotgames.com/it/accessibility',
  'pl-PL': 'https://www.riotgames.com/pl/accessibility',
  'pt-BR': 'https://www.riotgames.com/pt-br/accessibility',
  'ro-RO': 'https://www.riotgames.com/ro/accessibility',
  'ru-RU': 'https://www.riotgames.com/ru/accessibility',
  'tr-TR': 'https://www.riotgames.com/tr/accessibility',
  'ja-JP': 'https://www.riotgames.com/ja/accessibility',
  'ko-KR': 'https://www.riotgames.com/ko/accessibility',
  'zh-TW': 'https://www.riotgames.com/en/accessibility',
  'th-TH': 'https://www.riotgames.com/th/accessibility',
  'en-PH': 'https://www.riotgames.com/en/accessibility',
  'en-SG': 'https://www.riotgames.com/en/accessibility',
  'id-ID': 'https://www.riotgames.com/id/accessibility',
  'vi-VN': 'https://www.riotgames.com/vi/accessibility'
}

class Footer extends Component {
  renderImages () {
    return (
      <div class="images">
        <a class="item riot" href="http://www.riotgames.com" target="_blank">
          <Image
            class="image"
            src="/footer/riot-logo.png"
            size="114x32"
            alt="Riot Games"
          />
        </a>
        <a class="item lol" href="https://leagueoflegends.com" target="_blank">
          <Image
            class="image"
            src="/footer/lol.png"
            size="83x32"
            alt="League of Legends"
          />
        </a>
      </div>
    )
  }

  renderCopyright () {
    const year = new Date().getFullYear()

    return (
      <div class="copyright">
        { locale.translate('footer.copyright', { year }) }
        <div class="links">
          <a
            class="link"
            href={ localeToTermsOfService[locale.get()] }
            target="_blank">
            { locale.translate('footer.terms') }
          </a>
          <a
            class={ locale.get() === 'ko-KR' ? 'link bold' : 'link' }
            href={ localeToPrivacyNotice[locale.get()] }
            target="_blank">
            { locale.translate('footer.privacy') }
          </a>
          <a
            class="link"
            href={ localeToAccessibility[locale.get()] }
            target="_blank">
            { locale.translate('footer.accessibility') }
          </a>
          <CookiePreferences/>
        </div>
      </div>
    )
  }

  render () {
    return (
      <footer class={ this.constructor.name }>
        <div class="group-images-copyright">
          { this.renderImages() }
          { this.renderCopyright() }
        </div>
      </footer>
    )
  }
}

export default Footer
